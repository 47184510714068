import logo from "./logo.svg";
import Modal from "./modal";
import "./App.css";
import VR from "../src/img/VR_desktop.svg";
import People from "../src/img/people_desktop.svg";
import mobileVR from "../src/img/VR_mobile.svg";
import mobilePeople from "../src/img/people_mobile.svg";
import Kementerian from "../src/img/kementerian-logo.png";
import FAMA from "../src/img/FAMA.svg";
import VTF from "../src/img/vtf.svg";
import poweredBy from "../src/img/PoweredBy.svg";
import topBubble from "../src/img/topBubble.svg";
import mobilePoweredBy from "../src/img/mobile_PoweredBy.svg";
import mobileVTF from "../src/img/mobile_vtf.svg";
import circle1 from "../src/img/circle1.svg";
import circle2 from "../src/img/circle2.svg";
import circle3 from "../src/img/circle3.svg";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={topBubble} alt="Top Bubble" id='topBubble' className="mobile-hide-elem"/>
        <img src={circle1} id='circle1' className="mobile-hide-elem"/>
        <img src={circle2} id='circle2' className="mobile-hide-elem"/>
        <img src={circle3} id='circle3' className="mobile-hide-elem"/>
        <div id="images" className="mobile-images">
          <img src={VR} alt="VR headset image" id="vr-desktop" className="mobile-hide-elem"/>
          <img src={People} alt="People image" id="people-desktop" className="mobile-hide-elem"/>
          <img src={mobileVR} alt="VR headset image" id="vr-desktop" className="desktop-hide-elem"/>
          <img src={mobilePeople} alt="People image" id="people-desktop" className="desktop-hide-elem"/>

          <div id="footerIcons">
            <div id="topFooter" className="mobile-hide-elem">
            <img src={Kementerian} alt="Government Icon" id="kementerianIcon" />
            <img src={FAMA} alt="FAMA Icon" id="famaIcon" />
            </div>
            <div id="bottomFooter">
              <img src={poweredBy} alt="Powered By Image" id="poweredByIcon" className="mobile-hide-elem"/>
              <img src={VTF} alt="VTF Logo" id="vtfIcon" className="mobile-hide-elem"/>
              <img src={mobilePoweredBy} alt="Powered By Image" id="poweredByIcon" className="desktop-hide-elem"/>
              <img src={mobileVTF} alt="VTF Logo" id="vtfIcon" className="desktop-hide-elem"/>
            </div>
          </div>
        </div>
        <Modal />
      </header>
    </div>
  );
}

export default App;
