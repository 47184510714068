import React, {useState} from "react";
import AgroBazaar from "../src/img/AgroBazaar.svg"
import Dropdown from "../src/img/dropdown.svg"
import axios from "axios";
import menteri from '../src/img/kementerian-logo.png'
import fama from '../src/img/FAMA.svg'

const Modal = () => {
    const [dropdownValue, setDropdownValue] = useState("Select Age Range");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const ageRanges = ['Children (0 - 12 years old)', 'Primary School Student (7 - 12 years old)', 'High School Student (13 - 17 years old)', 'University Student (18 - 25 years old)', 'Adult (25 - 59 years old)', 'Senior Citizen (60 years old and above)'];
    const [ageRangeSelection, setAgeRangeSelection] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone_num, setPhoneNum] = useState('');
    const [nameError, setNameError] = useState(false);
    const [ageRangeError, setAgeRangeError] = useState(false);
    const [emailError, setEmailError] = useState(0); // 0 for no error, 1 for empty email, 2 for invalid email
    const [phoneNumError, setPhoneNumError] = useState(0); // 0 for no error, 1 for empty phone number, 2 for invalid phone number length, 3 for invalid phone number characters

    const handleAgeRangeSelection = (ageRange) => {
        if (ageRange == ageRangeSelection) { // for deselecting the age range
            setAgeRangeSelection('');
            setDropdownValue('Select Age Range');
        } else {
            setAgeRangeSelection(ageRange);
            setDropdownValue(ageRange);
            setDropdownOpen(false);
        }
        
    }

    const handleSubmit = async() => {
        setNameError(false);
        setAgeRangeError(false);
        setEmailError(0);
        setPhoneNumError(0);
        if (name == '') {
            setNameError(true);
        }
        if (ageRangeSelection == '') {
            setAgeRangeError(true);
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email == '') {
            setEmailError(1);
        } else if (!emailRegex.test(email)) {
            setEmailError(2);
        }
        const phoneNumRegex = /^-?\d+$/;
        if (phone_num == '') {
            setPhoneNumError(1);
        } else if (phone_num.length < 8 || phone_num.length > 11) {
            setPhoneNumError(2);
        } else if (!phoneNumRegex.test(phone_num)) {
            setPhoneNumError(3);
        }
        try {
            const response = await axios.post(
              "https://api.virtualtechfrontier.com/wp-json/api/fama-registration",
              {
                phone_num: phone_num,
                name: name,
                email: email,
                age_range: ageRangeSelection
              }
            );
            if (response.data.code == 200) {
                console.log("Registration successful");
                window.location.href = "https://www.spatial.io/s/FAMA-Agro-Bazaar-Metaverse-66bb26b070169892ae8bf411";
                
            }
          } catch (error) {
            console.log("Invalid phone number");
          }
    };
  return (
  <div id="modal">
    <div id='icons' className='desktop-hide-elem'>
        <img src={menteri}/>
        <img src={fama}/>
    </div>
    <div id='AgroBazaar'>
        <img src={AgroBazaar} alt="Agro Bazaar Logo" />
    </div>
    <div id="inputFields">
        <div id="name" className="inputFieldsContainer">
            <label for="name">Name</label>
            <input type="text" onChange={(e)=>setName(e.target.value)} value={name} required></input>
            <p className="errorMsg">{nameError ? 'Please enter your name.' : ''}</p>
        </div>
        <div id="email" className="inputFieldsContainer">
        <label for="email">Email</label>
        <input type="email" onChange={(e)=>setEmail(e.target.value)} value={email} required></input>
        <p className="errorMsg">{emailError == 1 ? "Please enter your email address." : emailError == 2 ? "Please enter a valid email address." : ""}</p>
        </div>
        <div id="phone" className="inputFieldsContainer">
        <label for="phone">Phone Number</label>
        <input type="tel" onChange={(e)=>setPhoneNum(e.target.value)} value={phone_num} required></input>
        <p className="errorMsg">{phoneNumError == 1 ? "Please enter your mobile number." : phoneNumError == 2 ? "Please enter a valid mobile number." : phoneNumError == 3 ? "Mobile number can only contain digits." : ""}</p>
        </div>


        <div id="age" className="inputFieldsContainer">
        <label for="age">Age Range</label>
        <span onClick={()=>{setDropdownOpen(!dropdownOpen); console.log(dropdownOpen)}}>
            <p id='ageText'>{dropdownValue}</p>
            <img src={Dropdown} alt="Dropdown Icon" className={`${dropdownOpen ? 'flipIcon' : ''}`} />
        </span>
        {dropdownOpen ? (<div id="dropdownMenu" >
            <div>
            {ageRanges.map((ageRange, index) => (
                <p key={ageRange} onClick={()=>handleAgeRangeSelection(ageRange)} className={ageRangeSelection == ageRange ? 'selectAgeRange' : ""}>{ageRange}</p>
                
            ))}
            </div>
            </div>) : null}
            {ageRangeError ? (<p className="errorMsg">Please select one option.</p>) : null}
        </div>
        

    </div>
    <div id="submission">
        <button onClick={handleSubmit}>Enter Metaverse</button>
        <p>Note that your data is secure with us and will not be shared with external parties.</p>
    </div>

  </div>
  );
};
export default Modal;
